import React, { useEffect } from 'react'
import { sentryInit } from 'lib/sentry'

export default function InitFathom(): JSX.Element {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      sentryInit()
    }
  }, [])

  return <></>
}
