import { useEffect } from 'react'
import Router from 'next/router'
import useSWR from 'swr'
interface Props {
  redirectTo?: string
  redirectIfFound?: string
}

interface ReturnType {
  user: User
  isLoading: boolean
  isFinished: boolean
}
interface User {
  email: string
  faunaSecret: string
  issuer: string
  maxAge: number
  publicAddress: string
  createdAt: number
}

interface FetchError extends Error {
  info: string
  status: number
}

const fetcher = async (url) => {
  const res = await fetch(url)
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.') as FetchError
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json().then((res) => {
    return { user: res?.user || null }
  })
}

const onSuccess = (_) => {
  // console.log(_)
}

const onError = (_) => {
  console.error(_)
}

const onErrorRetry = (error, key, config, revalidate, { retryCount }) => {
  if (retryCount >= 10) return
  setTimeout(() => revalidate({ retryCount }), 1000)
}

export const useUser = ({ redirectTo = null, redirectIfFound = null }: Props): ReturnType => {
  const { data, error, isValidating } = useSWR('/api/user', fetcher, {
    onSuccess: onSuccess,
    onError: onError,
    onErrorRetry: onErrorRetry,
  })
  const user = data?.user ?? null
  const finished = Boolean(data)
  const hasUser = Boolean(user)

  useEffect(() => {
    if (!finished) return
    if (redirectTo && !hasUser) {
      Router.push(redirectTo)
    } else if (redirectIfFound && hasUser) {
      Router.push(redirectIfFound)
    }
  }, [redirectTo, redirectIfFound, finished, hasUser])

  return { user, isLoading: isValidating, isFinished: finished }
}
