import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
// import { ApolloProvider } from '@apollo/client'
// import { useApollo } from 'lib/apolloClient'

import '../styles/fonts.css'
import '../styles/index.css'

import LogRocket from 'components/logRocket'
import Sentry from 'components/sentry'
import { useUser } from 'lib/hooks'

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  // const apolloClient = useApollo(pageProps)
  const { user } = useUser({})

  return (
    // <UserProvider user={user}>
    // <ApolloProvider client={apolloClient}>
    <>
      <LogRocket user={user} />
      <Sentry />
      <Component {...pageProps} />
    </>
    // </ApolloProvider>
    // </UserProvider>
  )
}
